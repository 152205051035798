import SvgIconStyle from '../SvgIconStyle';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  invoice: getIcon('ic_invoice'),
  users: getIcon("ic_users"),
  management: getIcon("id_management"),
  hub: getIcon("ic_hub"),
  metrics: getIcon("ic_metrics"),
  report: getIcon("ic_report"),
  inventory: getIcon("ic_inventory"),
};

const navConfig = [
  {
    subheader: 'Menú',
    items: [
      {
        title: 'Órdenes',
        path: '/orders',
        icon: ICONS.invoice,
        permission: "order",
        children: [
          { title: 'Listado de órdenes', path: '/orders' },
        ],
      },
      {
        title: 'Usuarios',
        path: '/users',
        icon: ICONS.users,
        permission: "users",
        children: [
          { title: 'Listado de usuarios', path: '/users' },
        ],
      },
      {
        title: 'Bodegas',
        path: '/hub',
        icon: ICONS.hub,
        permission: "hub",
        children: [
          { title: 'Listado de bodegas', path: '/hub' },
        ],
      },
      {
        title: 'Management',
        path: '/management',
        icon: ICONS.management,
        permission: "management",
        children: [
          { title: 'General', path: '/management' },
          { title: 'Errores Shopify', path: '/management/error' }
        ],
      },
      {
        title: 'Management',
        path: '/management',
        icon: ICONS.management,
        permission: "managementCompany",
        children: [
          { title: 'General', path: '/management' },
        ],
      },
      {
        title: 'Metricas',
        path: '/metrics',
        icon: ICONS.metrics,
        permission: "metrics",
        children: [
          { title: 'errores', path: '/metrics' },
        ],
      }, 
      {
        title: 'Reportes',
        path: '/report',
        icon: ICONS.report,
        permission: "report",
        children: [
          { title: 'Reportes', path: '/report' },
        ],
      },
      {
        title: 'Inventario',
        path: '/inventory',
        icon: ICONS.inventory,
        permission: "inventory",
        children: [
          { title: 'Inventario', path: '/inventory' },
        ],
      }
    ],
  },
];

export default navConfig;
